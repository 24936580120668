
@font-face {
  font-family: "JosefinSans";
  src: local("JosefinSans"),
  url("./fonts/nunito/Nunito-Bold.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-JosefinSans {
  font-family: "JosefinSans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu > .ant-menu-item,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-item-active{
  border-radius: 10px !important;
  font-family: 'JosefinSans', sans-serif;
  font-size: 1.15em;
  padding: 2px 10px 8px 15px !important;
}
.ant-select {
  border-radius: 20px !important;
}
.ant-menu > .ant-menu-item-selected {
  background-color: #29327b !important;
  color: #fff !important;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover{
  color: #29327b !important;
  background-color: #ecf7fc !important;
  border-bottom: 2px solid #29327b !important;
}

.p-menubar > a[class="p-menubar-button"],
.p-menubar > a[class="p-menu-list"],
.p-menubar > a[class="p-menuitem"],
.p-menubar > .p-menuitem,
.p-menubar > .p-menu-list {
  display: none !important;
}
.p-menubar {
  height: 70px !important;
}
